<template>
  
  <div class="section">
    <!-- CONTAINER -->
    <div class="container">
        <!-- ROW -->
        <div class="row">
            <!-- Main Column -->
            <div class="col-md-12">
                <!-- section title -->
                <div class="section-title">
                    <h2 class="title">{{title}}</h2>
                    <div id="nav-carousel-2" class="VueCarousel-navigation"></div>
                </div>
                <!-- /section title -->
                
                <!-- owl carousel 2 -->
                <div id="owl-carousel-2" class="d-flex flex-wrap">
                    <videos></videos>
                </div>
                <!-- /owl carousel 2 -->
            </div>
            <!-- /Main Column -->
        </div>
        <!-- /ROW -->
    </div>
    <!-- /CONTAINER -->
</div>
     
</template>

<script>
import Mainaxios from '../Api'
import Videos from './Videos/Videos';
export default {
  components: { Videos },
  name: "SideComponent",
  props: {
    title: String,
  },
  data(){
        return{
            news:[],
            marquees:[],
            paused:false,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    created(){
       
    },
    methods:{
        async fetchData(){
			await Mainaxios.get("api/banner").then(({data}) => (this.news = data));
        },
        
       
        
    },
    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>