<template>
  <div>
    <footer id="footer">
        <div id="top-footer" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="footer-widget about-widget">
                            <div class="footer-logo">
                                <router-link to="/" class="logo"><img :src="$profilepath+this.profile.logo" alt=""></router-link>
                                <p>{{this.profile.about}}</p>
                            </div>
                        </div>
                        
                        <div class="footer-widget social-widget">
                            <div class="widget-title">
                                <h3 class="title">Follow Us</h3>
                            </div>
                            <ul>
                                <li><a v-show="this.profile.facebook" :href="this.profile.facebook"  target="_blank" class="facebook"><fa :icon="['fab', 'facebook']" /></a></li>
                                <li><a v-show="this.profile.twitter" :href="this.profile.twitter"  target="_blank" class="twitter"><fa :icon="['fab', 'twitter']" /></a></li>
                                <li><a v-show="this.profile.google" :href="this.profile.google" target="_blank"  class="google"><fa :icon="['fab', 'google']" /></a></li>
                                <li><a v-show="this.profile.instagram" :href="this.profile.instagram"  target="_blank" class="instagram"><fa :icon="['fab', 'instagram']" /></a></li>
                                <li><a v-show="this.profile.youtube" :href="this.profile.youtube"  target="_blank" class="youtube"><fa :icon="['fab', 'youtube']" /></a></li>
                            </ul>
                        </div>
                        
                        <div class="footer-widget subscribe-widget">
                            <div class="widget-title">
                                <h2 class="title">Subscribe to Newslatter</h2>
                            </div>
                            <form>
                                <input class="input" type="email" placeholder="Enter Your Email">
                                <button class="input-btn">Subscribe</button>
                            </form>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="footer-widget">
                            <div class="widget-title">
                                <h2 class="title">Featured Posts</h2>
                            </div>

                            <article class="article widget-article" v-for="obj in footer.slice(0,3)" :key="obj.id">
                                <div class="article-img" v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <router-link :to="'/single-post/'+obj.id">
                                        <img height="80" :src="$urlpath+image" alt="">
                                    </router-link>
                                </div>
                                
                                <div class="article-body">
                                    <h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
                                    <ul class="article-meta">
                                        <li><fa :icon="['fas', 'clock']" /> {{obj.postdate|calendar}}</li>
                                    </ul>
                                </div>
                            </article>
                           
                        </div>
                    </div>
                    
                    <!-- Column 3 -->
                    <div class="col-md-4">
                        <!-- footer galery -->
                        <div class="footer-widget galery-widget">
                            <div class="widget-title">
                                <h2 class="title">Flickr Photos</h2>
                            </div>
                            <ul>
                                <li v-for="obj in footer.slice(3,11)" :key="obj.id">
                                   <router-link :to="'/single-post/'+obj.id" v-for="(image,index) in obj.images" :key="index">
                                        <img v-if="index==0" height="80" :src="$urlpath+image" alt="">
                                    </router-link>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="bottom-footer" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-md-push-6">
                        <ul class="footer-links">
                            
                            <li><router-link to="/about">About us</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                            <li><router-link to="/ads">Advertisement</router-link></li>
                            <li><router-link to="/privacy">Privacy</router-link></li>
                            
                        </ul>
                    </div>
                    
                    <div class="col-md-6 col-md-pull-6">
                        <div class="footer-copyright">
                            <span>
                                Copyright All rights reserved by {{this.profile.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div @click="topFunction" id="back-to-top">
            <fa :icon="['fas', 'arrow-up']" />
        </div>
    </footer>
    
  </div>
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "Navbar",
  props: {
    msg: String,
  },
  data(){
        return{
            footer:[],
            profile:[],
            paused:false,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/profile").then(({data}) => (this.profile = data));
			await Mainaxios.get("api/footer").then(({data}) => (this.footer = data));
        },
        topFunction() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
