<template>
  <div>
	<div class="section">
		<div class="container">
			<div class="row">
				<div class="col-md-8">

					<ul class="article-breadcrumb">
						<li><router-link to="/" >Home</router-link></li>
						<li><router-link :to="'/category/'+post.categ_id">{{post.categ_name}}</router-link></li>
					</ul>
				
					<article class="article article-post">
						<div class="article-share" v-if="post.images!='logo.png'">
							
							<a v-show="this.profile.facebook" :href="this.profile.facebook"  target="_blank" class="facebook"><fa :icon="['fab', 'facebook']" /></a>
							<a v-show="this.profile.twitter" :href="this.profile.twitter"  target="_blank" class="twitter"><fa :icon="['fab', 'twitter']" /></a>
							<a v-show="this.profile.instagram" :href="this.profile.instagram"  target="_blank" class="instagram"><fa :icon="['fab', 'instagram']" /></a>

						</div>
						<div class="article-main-img" v-if="post.images!='logo.png'">
							<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
								<slide v-for="(image,index) in post.images" :key="index">
									<img style="max-height:420px" :src="$urlpath+image" alt="">
								</slide>
							</carousel>
						</div>
						<div class="article-body">
							<ul class="article-info">
								<li class="article-category"><router-link :to="'/category/'+post.categ_id">{{post.categ_name}}</router-link></li>
								<li class="article-type"><fa :icon="['far', 'file']" /></li>
							</ul>
							<h3 class="article-title">{{post.title}}</h3>
							<ul class="article-meta">
								<li><fa :icon="['far', 'clock']" /> {{post.postdate|calendar}}</li>
							</ul>
							<p v-html="post.content"></p>
						</div>
					</article>
					
					<div class="widget-tags">
						<ul>
							<li v-for="tag in post.tags" :key="tag.id">
								<router-link to="/">{{tag}}</router-link>
							</li>
						</ul>
					</div>
					
					<!-- <div class="article-comments">
						<div class="section-title">
							<h2 class="title">Comments</h2>
						</div>
							
						<div class="media">
							<div class="media-left">
								<img src="img/av-1.jpg" alt="">
							</div>
							<div class="media-body">
								<div class="media-heading">
									<h5>John Doe <span class="reply-time">April 04, 2017 At 9:30 AM</span></h5>
								</div>
								<p>Eu usu aliquip vivendo. Impedit suscipit invidunt te vel, sale periculis id mea. Ne nec atqui paulo,</p>				
								<a href="#" class="reply-btn">Reply</a>
							</div>
							
							<div class="media">
								<div class="media-left">
									<img src="img/av-2.jpg" alt="">
								</div>
								<div class="media-body">
									<div class="media-heading">
										<h5>John Doe <span class="reply-time">April 04, 2017 At 9:30 AM</span></h5>
									</div>
									<p>Eu usu aliquip vivendo. Impedit suscipit invidunt te vel, sale periculis id mea. Ne nec atqui paulo,</p>				
									<a href="#" class="reply-btn">Reply</a>
								</div>
								
								<div class="media">
									<div class="media-left">
										<img src="img/av-1.jpg" alt="">
									</div>
									<div class="media-body">
										<div class="media-heading">
											<h5>John Doe <span class="reply-time">April 04, 2017 At 9:30 AM</span></h5>
										</div>
										<p>Eu usu aliquip vivendo. Impedit suscipit invidunt te vel, sale periculis id mea. Ne nec atqui paulo,</p>				
										<a href="#" class="reply-btn">Reply</a>
									</div>
								</div>
							</div>
						</div>
						
						<div class="media">
							<div class="media-left">
								<img src="img/av-2.jpg" alt="">
							</div>
							<div class="media-body">
								<div class="media-heading">
									<h5>John Doe <span class="reply-time">April 04, 2017 At 9:30 AM</span></h5>
								</div>
								<p>Eu usu aliquip vivendo. Impedit suscipit invidunt te vel, sale periculis id mea. Ne nec atqui paulo,</p>				
								<a href="#" class="reply-btn">Reply</a>
							</div>
						</div>
					</div> -->
					
					<div class="article-reply-form">
						<div class="section-title">
							<h2 class="title">Leave a reply</h2>
						</div>
							
						<form>
							<input class="input" placeholder="Name" type="text">
							<input class="input" placeholder="Email" type="email">
							<input class="input" placeholder="Website" type="url">
							<textarea class="input" placeholder="Message"></textarea>
							<button class="input-btn">Send Message</button>
						</form>
					</div>
				</div>
				
				<!-- Aside Column -->
				<Side-Component/>
				<!-- /Aside Column -->
			</div>
			<!-- /ROW -->
		</div>
		<!-- /CONTAINER -->
	</div>
	<!-- /SECTION -->
	
	<bottom-ads />
	
		
	<div class="section" >
        <div class="container">
            <div class="row">
                <div class="col-md-12" v-show="this.relatedposts.length>=1">
                    <div class="section-title">
                        <h2 class="title">Related Post</h2>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-3 col-sm-6" v-for="obj in relatedposts" :key="obj.id">
                            <article class="article">
                                <div class="article-img">
									<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
										<slide v-for="(image,index) in obj.images" :key="index">
											<router-link :to="'/single-post/'+obj.id">
												<img height="196" :src="$urlpath+image" alt="">
											</router-link>
											<ul class="article-info">
												<li class="article-type"><fa :icon="['fas', 'camera']" /></li>
											</ul>
										</slide>
									</carousel>
                                </div>
                                <div class="article-body">
                                    <h4 class="article-title"><router-link :to="'/single-post/'+obj.id">Duis urbanitas eam in, tempor consequat.</router-link></h4>
                                    <ul class="article-meta">
                                        <li><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import Mainaxios from '../Api'
import BottomAds from '../components/BottomAds.vue';
export default {
  components: { BottomAds },
  name: "Banner",
  props: {
    msg: String,
  },
  data(){
        return{
			PostID:this.$route.params.id,
            post:[],
            relatedposts:[],
			profile:[],
            paused:false,
        }
    },
    beforeRouteUpdate (to, from, next) {
    	this.PostID = to.params.id
		this.fetchData();
    next()
	},
    methods:{
        getResults(page = 1) {
        Mainaxios.get('api/banner?page=' + page)
            .then(response => {
                this.posts = response.data;
            });
        },
        async fetchData(){
            await Mainaxios.get("api/singlepost/"+this.PostID).then(response => {
				[this.post = response.data.news, 
				this.relatedposts = response.data.related]
				window.scrollTo({top: 0, behavior: 'smooth'});
				})
				.catch(err=>{
					const status =  err.response.status
					if(status==404){
						this.$router.push({name:'Missing'})
					}
				});
			await Mainaxios.get("api/profile").then(({data}) => (this.profile = data));
        },
        
    },
    created(){
		this.fetchData();
		// window.scrollTo({top: 0, behavior: 'smooth'});
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>