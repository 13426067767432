import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Categ from "../views/Category.vue";
import SinglePost from "../views/SinglePost.vue";
import singleVideo from "../views/SingleVideo.vue";
import SearchPost from "../views/SearchPost.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => 
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => 
      import(/* webpackChunkName: "contact" */ "../views/About.vue"),
  },
  {
    path: "/advertisment",
    name: "Adver",
    component: () => 
      import(/* webpackChunkName: "ads" */ "../views/About.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => 
      import(/* webpackChunkName: "privacy" */ "../views/About.vue"),
  },
  {
    path: "/category/:id",
    name: "Category",
    component: Categ,
  },
  {
    path: "/single-post/:id",
    name: "SinglePost",
    component: SinglePost,
  },
  {
    path: "/single-video/:id",
    name: "singleVideo",
    component: singleVideo,
  },
  {
    path: "/search-results/:id",
    name: "searchResult",
    component: SearchPost,
  },
  
  {
    path: "*",
    name: "Missing",
    component: () => 
      import(/* webpackChunkName: "error" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
