<template>
    <div>
        <carousel :per-page="1" :autoplay="true" :loop="true" :paginationEnabled="false" :autoplayHoverPause="true"  
        :speed="1000" :autoplayTimeout="4000" v-show="this.ads.totalAds" >
            <slide v-for="obj in ads.data" :key="obj.id" v-show="obj" >
                <a :href="obj.link" target="blank"><img class="center-block" :src="$adspath+obj.image" alt=""></a>
            </slide>
            <slide >
                <a href="http://www.pressmediaenglishdaily.com" target="blank"><img class="center-block" src="../assets/images/press-media_1.jpg" alt=""></a>
            </slide>
            <slide >
                <a href="https://yoututor.in" target="blank"><img class="center-block" src="../assets/images/yoututor_1.jpg" alt=""></a>
            </slide>
        </carousel>
        <a href="http://www.pressmediaenglishdaily.com" target="blank">
        <img style="max-height:90px;" v-show="!this.ads.totalAds" class="center-block" src="../assets/images/press-media_1.jpg" alt=""></a>
    </div>
</template>

<script>
import Mainaxios from '../Api'

export default {
  name: "Ads",
  props: {

  },
  data(){
        return{
            ads:[],
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
			await Mainaxios.get("api/advertisments/top").then(({data}) => (this.ads = data));
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>