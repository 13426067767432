<template>
  
    <div class="col-md-4">
        <side-ads />
        
        <div class="widget social-widget">
            <div class="widget-title">
                <h2 class="title">Stay Connected </h2>
            </div>
            <ul class="text-center">
                <li><a v-show="this.profile.facebook" :href="this.profile.facebook"  target="_blank" class="facebook"><fa :icon="['fab', 'facebook']" /><br><span>Facebook</span></a></li>
                <li><a v-show="this.profile.twitter" :href="this.profile.twitter"  target="_blank" class="twitter"><fa :icon="['fab', 'twitter']" /><br><span>Twitter</span></a></li>
                <li><a v-show="this.profile.google" :href="this.profile.google" target="_blank"  class="google"><fa :icon="['fab', 'google']" /><br><span>Google+</span></a></li>
                <li><a v-show="this.profile.instagram" :href="this.profile.instagram"  target="_blank" class="instagram"><fa :icon="['fab', 'instagram']" /><br><span>Instagram</span></a></li>
                <li><a v-show="this.profile.youtube" :href="this.profile.youtube"  target="_blank" class="youtube"><fa :icon="['fab', 'youtube']" /><br><span>Youtube</span></a></li>
            </ul>
        </div>
        
        <div class="widget subscribe-widget">
            <div class="widget-title">
                <h2 class="title">Subscribe to Newslatter</h2>
            </div>
            <form>
                <input class="input" type="email" placeholder="Enter Your Email">
                <button class="input-btn">Subscribe</button>
            </form>
        </div>
        
        <div class="widget">
            <div class="widget-title">
                <h2 class="title">Most Read</h2>
            </div>
            
            <div id="owl-carousel-3" class="owl-carousel owl-theme center-owl-nav">
                
                <carousel :per-page="1" :autoplay="true" :loop="true" :paginationEnabled="false">
                    <slide v-for="(obj,index) in sidenews.slice(0,10)" :key="index">
                        <article class="article" >
                             <span v-if="obj.images.length>=1">
                                <div class="article-img" v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img height="270" :src="$urlpath+image" alt="">
                                    <ul class="article-info">
                                        <li class="article-type"><fa :icon="['fas', 'file']" /></li>
                                    </ul>
                                </div>
                            </span>
                            <span v-else>
                                <div class="article-img">
                                    <img height="270" :src="$profilepath+'logo.png'" alt="">
                                    <ul class="article-info">
                                        <li class="article-type"><fa :icon="['fas', 'file']" /></li>
                                    </ul>
                                </div>
                            </span>
                        
                        <div class="article-body">
                            <h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
                            <ul class="article-meta">
                                <li><fa :icon="['fas', 'clock']" /> {{obj.postdate|calendar}}</li>
                            </ul>
                        </div>
                        </article>
                    </slide>
                    
                </carousel>

            </div>
            
            <article class="article widget-article" v-for="(obj,index) in sidenews.slice(10,13)" :key="index" >
                <div class="article-img">
                    <carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
                        <slide v-for="(image,index) in obj.images" :key="index">
                            <router-link :to="'/single-post/'+obj.id">
                                <img height="80" :src="$urlpath+image" alt="">
                            </router-link>
                        </slide>
                    </carousel>
                </div>
                <div class="article-body">
                    <h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
                    <ul class="article-meta">
                        <li><fa :icon="['fas', 'clock']" /> {{obj.postdate|TimeAgo}}</li>
                    </ul>
                </div>
            </article>
            
        </div>
    </div>
    
 
</template>

<script>
import Mainaxios from '../Api'
import SideAds from './SideAds.vue';
export default {
  name: "SideComponent",
  components:{
    SideAds
  },
  props: {
    msg: String,
  },
  data(){
        return{
            user:[],
            profile:[],
            sidenews:[],
            marquees:[],
            paused:false,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/sidenews").then(({data}) => (this.sidenews = data));
            await Mainaxios.get("api/profile").then(({data}) => (this.profile = data));
            await Mainaxios.get("api/user/1").then(({data}) => (this.user = data));
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>