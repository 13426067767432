<template>
  
    <div class="col-md-4">
        <div class="widget">
            <div class="widget-title">
                <h2 class="title">Featured Posts</h2>
            </div>
            
            <div id="owl-carousel-4" class="owl-carousel owl-theme">
                <carousel :per-page="1" :autoplay="true" :loop="true" >
                    <slide v-for="(obj,index) in sidenews" :key="index">
                        <article class="article thumb-article" >
                            <span v-if="obj.images.length>=1">
                                <div class="article-img"  v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img  height="360" :src="$urlpath+image" alt="">
                                </div>
                            </span>
                            <span v-else>
                                <div class="article-img">
                                    <img height="360" :src="$profilepath+'logo.png'" alt="">
                                </div>
                            </span>
                        <div class="article-body">
                            <ul class="article-info">
                                <li class="article-category"><router-link :to="'/category/'+obj.categ_id">{{obj.categ_name}}</router-link></li>
                                <li class="article-type"><fa :icon="['fas', 'camera']" /></li>
                            </ul>
                            <h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
                            <ul class="article-meta">
                                <li><fa :icon="['fas', 'clock']" /> {{obj.postdate|calendar}}</li>
                            </ul>
                        </div>
                        </article>
                    </slide>
                    
                </carousel>

                
            </div>
        </div>
        
        <div class="widget galery-widget">
            <div class="widget-title">
                <h2 class="title">Flickr Photos</h2>
            </div>
            <ul>
                <li v-for="obj in sideimgs" :key="obj.id">
                    <router-link :to="'/single-post/'+obj.id" v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                        <img height="80" :src="$urlpath+image" alt="">
                    </router-link>
                </li>
            </ul>
        </div>
        
        <div class="widget tweets-widget">
            <div class="widget-title">
                <h2 class="title">Recent Tweets</h2>
            </div>
            <ul>
                <li class="tweet">
                    <i class="fa fa-twitter"></i>
                    <div class="tweet-body">
                        <p><a href="#">@magnews</a> Populo tritani laboramus ex mei, no eum iuvaret ceteros euripidis <a href="#">https://t.co/DwsTbsmxTP</a></p>
                    </div>
                </li>
                <li class="tweet">
                    <i class="fa fa-twitter"></i>
                    <div class="tweet-body">
                        <p><a href="#">@magnews</a> Populo tritani laboramus ex mei, no eum iuvaret ceteros euripidis <a href="#">https://t.co/DwsTbsmxTP</a></p>
                    </div>
                </li>
                <li class="tweet">
                    <i class="fa fa-twitter"></i>
                    <div class="tweet-body">
                        <p><a href="#">@magnews</a> Populo tritani laboramus ex mei, no eum iuvaret ceteros euripidis <a href="#">https://t.co/DwsTbsmxTP</a></p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    
 
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "SideComponent",
  props: {
    msg: String,
  },
  data(){
        return{
            sidenews:[],
            sideimgs:[],
            paused:false,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/bottomnews").then(({data}) => (this.sidenews = data));
            await Mainaxios.get("api/bottomimg").then(({data}) => (this.sideimgs = data));
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>