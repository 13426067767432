<template>
  <div>
	<div class="section">
		<div class="container">
			<div class="row">
				<div class="col-md-8">

					<ul class="article-breadcrumb">
						<li><router-link to="/" >Home</router-link></li>
                        <li><a href="https://www.youtube.com/watch?v=pcHNuanU9_c&list=PLcjp9X1am2yO7nzeyAvH9VK79hG1pncpV">Youtube</a></li>
                        <li>{{video.snippet.title|truncate(30)}}</li>
					</ul>
				
					<article id="article" class="article article-post">
						
						<div class="article-body">
							
                                <h2>{{video.snippet.title}}</h2>
                                <small >{{video.snippet.channelTitle}}</small>

                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe :src="url" frameborder="0" class="embed-responsive-item"></iframe>
                                </div>
                                <p >
                                    {{video.snippet.description}}
                                </p>
                            
						</div>
					</article>
					
					<div class="widget-tags">
						<ul>
							<li v-for="tag in post.tags" :key="tag.id">
								<router-link to="/">{{tag}}</router-link>
							</li>
						</ul>
					</div>
				
					<div class="article-reply-form">
						<div class="section-title">
							<h2 class="title">Leave a reply</h2>
						</div>
							
						<form>
							<input class="input" placeholder="Name" type="text">
							<input class="input" placeholder="Email" type="email">
							<input class="input" placeholder="Website" type="url">
							<textarea class="input" placeholder="Message"></textarea>
							<button class="input-btn">Send Message</button>
						</form>
					</div>
				</div>
				
				<!-- Aside Column -->
				<Side-Component/>
				<!-- /Aside Column -->
			</div>
			<!-- /ROW -->
		</div>
		<!-- /CONTAINER -->
	</div>
	<!-- /SECTION -->
	
	<bottom-ads />
		
	<div class="section" >
        <div class="container">
            <div class="row">
                <div class="col-md-12" v-show="this.relatedposts.length>=1">
                    <div class="section-title">
                        <h2 class="title">Related Post</h2>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-3 col-sm-6" v-for="obj in relatedposts" :key="obj.id">
                            <article class="article">
                                <div class="article-img">
									<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
										<slide v-for="(image,index) in obj.images" :key="index">
											<router-link :to="'/single-post/'+obj.id">
												<img height="196" :src="$urlpath+image" alt="">
											</router-link>
											<ul class="article-info">
												<li class="article-type"><fa :icon="['fas', 'camera']" /></li>
											</ul>
										</slide>
									</carousel>
                                </div>
                                <div class="article-body">
                                    <h4 class="article-title"><router-link :to="'/single-post/'+obj.id">Duis urbanitas eam in, tempor consequat.</router-link></h4>
                                    <ul class="article-meta">
                                        <li><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import BottomAds from '../components/BottomAds.vue';
export default {
  components: { BottomAds },
  name: "Banner",
  data(){
        return{
			PostID:5,
            videoId: null,
            video: null,
            url: null,
            post:[],
            relatedposts:[],
            paused:false,
        }
    },
    beforeRouteUpdate (to, from, next) {
    	this.video = to.params.video
    next()
	},
    created(){
            if(this.$route.params.video === undefined){
                this.$router.push('/');
                console.log('error')
            }
            this.videoId = this.$route.params.id;
            this.url = 'https://www.youtube.com/embed/'+this.videoId+'?autoplay=1';
            this.video = this.$route.params.video;
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
    methods:{
       topFunction() {
            
        }
       
        
    },
   
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#article p {
    margin-top: 20px;
}

</style>