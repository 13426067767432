let youtube = require('axios');


let BASE_URL = 'https://youtube.googleapis.com/youtube/v3/playlistItems';



module.exports = function(options,callback){
    if(!options.apiKey){
        throw new Error('Youtube required a key');
    }

    let params = {
        part: 'snippet' ,
        maxResults: 4,
        key: options.apiKey,
        playlistId: options.playlist,
        pageToken: options.pageToken
    };

    const instance = youtube.create(params);

    instance.get(BASE_URL,{params})
    .then(response =>{
        if(callback){
            callback(response.data)
        }
    })
   

}
