<template>
  <div>
      <header id="header">
			<!-- Top Header -->
			<div id="top-header">
				<div class="container">
					<div class="header-links">
						<ul>
							<li><router-link to="/about">About us</router-link></li>
							<li><router-link to="/contact">Contact</router-link></li>
							<!-- <li><router-link to="/advertisment">Advertisement</router-link></li> -->
							<li><router-link to="/privacy">Privacy</router-link></li>
							<li><a href="https://admin.bharatsamachartvnews.com"><fa :icon="['fas', 'sign-in-alt']" /> Login</a></li>
						</ul>
					</div>
					<div class="header-social">
						<ul>
							<li><a v-show="this.profile.facebook" :href="this.profile.facebook"  target="_blank" class="facebook"><fa :icon="['fab', 'facebook']" /></a></li>
							<li><a v-show="this.profile.twitter" :href="this.profile.twitter"  target="_blank" class="twitter"><fa :icon="['fab', 'twitter']" /></a></li>
							<li><a v-show="this.profile.google" :href="this.profile.google" target="_blank"  class="google"><fa :icon="['fab', 'google']" /></a></li>
							<li><a v-show="this.profile.instagram" :href="this.profile.instagram"  target="_blank" class="instagram"><fa :icon="['fab', 'instagram']" /></a></li>
							<li><a v-show="this.profile.youtube" :href="this.profile.youtube"  target="_blank" class="youtube"><fa :icon="['fab', 'youtube']" /></a></li>
						</ul>
					</div>
				</div>
			</div>
			<!-- /Top Header -->
			
			<!-- Center Header -->
			<div id="center-header">
				<div class="container">
					<div class="row">
						<div class="col-4">
							<div class="header-logo">
								<router-link to="/" class="logo"><img :src="$profilepath+this.profile.logo" alt=""></router-link>
							</div>
						</div>
						<div class="col-8 top-ads">
							<top-ads />
						</div>

					</div>
					
				</div>
			</div>
			<!-- /Center Header -->
			
			<!-- Nav Header -->
			<div id="nav-header">
				<div class="container">
					<nav id="main-nav" :class="{'nav-collapse':sideMenu}">
						<div class="nav-logo">
							<a href="#" class="logo"><img :src="$profilepath+this.profile.logo" alt=""></a>
						</div>
						<ul class="main-nav nav navbar-nav">
							<li><router-link to="/">Home</router-link></li>
							<li v-for="(obj,index) in categories" :key="index" >
								<router-link v-if="index < 5" :to="{ name: 'Category', params:{id:obj.id}}">{{obj.categ_name}}</router-link>
							</li>
							<li class="dropdown"><a href="#">More</a>
							<div class="dropdown-content">
								<span v-for="(obj,index) in categories" :key="index">
									<router-link v-if="index >= 5" :to="{ name: 'Category', params:{id:obj.id}}">{{obj.categ_name}}</router-link>
								</span>
								<!-- <router-link :to="{ name: 'Category', params:{id:obj.id}}">{{obj.categ_name}}</router-link> -->
								
							</div>
							</li>
							
						</ul>
					</nav>
					<div class="button-nav">
						<button class="search-collapse-btn" @click="toggleSearch" :class="{'active':searchMenu}"><fa :icon="['fas', 'search']" /></button>
						<button class="nav-collapse-btn" @click="toggleMenu"><fa :icon="['fas', 'bars']" /></button>
						<div class="search-form" :class="{'search-collapse':searchMenu}">
							<form>
								<input v-model="searchItem" class="input" @keyup="search" type="text" name="search" placeholder="Search">
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- /Nav Header -->
		</header>
		<!-- /Header -->
    
  </div>
</template>

<script>
import Mainaxios from '../Api'
import TopAds from './TopAds.vue'
import Swal from 'sweetalert2'
var _ = require('lodash');
export default {
  name: "Navbar",
  components:{
	  TopAds
  },
  props: {
    msg: String,
  },
  data(){
        return{
            categories:[],
            profile:[],
            paused:false,
			sideMenu:false,
			searchMenu:false,
			searchItem:'',
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/profile").then(({data}) => (this.profile = data));
			await Mainaxios.get("api/categories").then(({data}) => (this.categories = data));
        },
		toggleMenu(){
			this.sideMenu = !this.sideMenu;
		},
		toggleSearch(){
			this.searchMenu = !this.searchMenu;	
			this.searchItem = '';
		},
		search:_.debounce(function () {
                 Mainaxios.get("api/search?q="+this.searchItem)
                .then(({data}) => (
					this.$router.push({name: 'searchResult', params:{id:this.searchItem, posts:data.data}}),
					this.toggleSearch()
					))
                .catch( error => {
                    let status = error.response.status;
                    this.searchItem = ''; 
                    if(status==400){
                        this.toggleSearch();
                        Swal.fire({
                            title: 'No Results!',
                            text: "Try Somthing Else! No such post in our record.",
                            icon: 'warning'
                            })
                    }else{
                        Swal.fire('error!', 'There is somthing wrong. Please Try Again!','warning');
                     
                    }
                });
               
            }, 1000),
		
        
    },
    created(){

    }
};
</script>

<style scoped>
.dropdown {
  position: relative;
  
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #07090c;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown:hover .dropdown-content {display: block;}

.dropdown-content a:hover {background-color: #2c2c2c;}

@media only screen and (max-width: 767px) {
	.dropdown-content a {
		margin-left: 15px;
	}
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
