<template>
  <div>
		<div class="section">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="section-title">
							<h2 class="title">Search Results</h2>
						</div>
						<div class="alert alert-danger alert-dismissible">
							<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
							<h5 v-if="this.resultposts.length==1"><fa :icon="['far', 'search']" /> {{this.resultposts.length}} Result found!</h5>
							<h5 v-else><fa :icon="['far', 'search']" /> {{this.resultposts.length}} Results found!</h5>
							Showing search results for: {{this.resultfor}}
						</div>
						<div class="tab-content">
							<div id="tab" class="tab-pane fade in active">
								
								<div class="row">
									<div class="col-md-3 col-sm-6" v-for="obj in resultposts.slice(0,4)" :key="obj.key">
										<article class="article">
												<div class="article-img" v-if="obj.images.length>=1">
											<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
												<slide v-for="(image,index) in obj.images" :key="index">
													<router-link :to="'/single-post/'+obj.id">
														<img height="200" :src="$urlpath+image" alt="">
													</router-link>
													<ul class="article-info">
														<li class="article-type"><fa :icon="['fas', 'camera']" /></li>
													</ul>
												</slide>
											</carousel>
												</div>

												<div class="article-img" v-else>
													<router-link :to="'/single-post/'+obj.id">
														<img height="200" :src="$profilepath+'logo.png'" alt="">
													</router-link>
													<ul class="article-info">
														<li class="article-type"><fa :icon="['fas', 'camera']" /></li>
													</ul>
												</div>

											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</li>
												</ul>
											</div>
										</article>
									</div>
									
								</div>

								<div class="row">
									<div class="col-md-3 col-sm-6" v-for="obj in resultposts.slice(4,8)" :key="obj.key">
										<article class="article">
												<div class="article-img" v-if="obj.images.length>=1">
											<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
												<slide v-for="(image,index) in obj.images" :key="index">
													<router-link :to="'/single-post/'+obj.id">
														<img height="200" :src="$urlpath+image" alt="">
													</router-link>
													<ul class="article-info">
														<li class="article-type"><fa :icon="['fas', 'camera']" /></li>
													</ul>
												</slide>
											</carousel>
												</div>

												<div class="article-img" v-else>
													<router-link :to="'/single-post/'+obj.id">
														<img height="200" :src="$profilepath+'logo.png'" alt="">
													</router-link>
													<ul class="article-info">
														<li class="article-type"><fa :icon="['fas', 'camera']" /></li>
													</ul>
												</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</li>
												</ul>
											</div>
										</article>
									</div>
									
								</div>
								
								
								<div class="row">
									<div class="col-md-4 col-sm-6">
										<article class="article widget-article" v-for="obj in resultposts.slice(8,12)" :key="obj.key" >
											<div class="article-img" v-if="obj.images.length>=1">
												<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
													<slide v-for="(image,index) in obj.images" :key="index">
														<router-link :to="'/single-post/'+obj.id">
															<img height="80" :src="$urlpath+image" alt="">
														</router-link>
													</slide>
												</carousel>
											</div>
											<div class="article-img" v-else>
												<router-link :to="'/single-post/'+obj.id">
													<img height="80" :src="$profilepath+'logo.png'" alt="">
												</router-link>
											</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|myDate}}</li>
												</ul>
											</div>
										</article>
									</div>
									
									<div class="col-md-4 col-sm-6">
										<article class="article widget-article" v-for="obj in resultposts.slice(12,16)" :key="obj.key" >
											<div class="article-img" v-if="obj.images.length>=1">
												<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
													<slide v-for="(image,index) in obj.images" :key="index">
														<router-link :to="'/single-post/'+obj.id">
															<img height="80" :src="$urlpath+image" alt="">
														</router-link>
													</slide>
												</carousel>
											</div>
											<div class="article-img" v-else>
												<router-link :to="'/single-post/'+obj.id">
													<img height="80" :src="$profilepath+'logo.png'" alt="">
												</router-link>
											</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|myDate}}</li>
												</ul>
											</div>
										</article>
									
									</div>
									
									<div class="col-md-4 col-sm-6">
										<article class="article widget-article" v-for="obj in resultposts.slice(16,20)" :key="obj.key" >
											<div class="article-img" v-if="obj.images.length>=1">
												<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
													<slide v-for="(image,index) in obj.images" :key="index">
														<router-link :to="'/single-post/'+obj.id">
															<img height="80" :src="$urlpath+image" alt="">
														</router-link>
													</slide>
												</carousel>
											</div>
											<div class="article-img" v-else>
												<router-link :to="'/single-post/'+obj.id">
													<img height="80" :src="$profilepath+'logo.png'" alt="">
												</router-link>
											</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|myDate}}</li>
												</ul>
											</div>
										</article>
									
									</div>
								</div>
							</div>

						</div>

						<!-- <div class="article-pagination">
							<pagination :data="news" @pagination-change-page="getResults"></pagination>
						</div> -->

					</div>
				</div>
			</div>
			
		</div>
    
    
  </div>
</template>

<script>
import Mainaxios from '../Api'
import Swal from 'sweetalert2'
export default {
	props: ['posts'],
  data(){
        return{
			resultposts:this.$route.params.posts,
			resultfor:this.$route.params.id,
            news:[],
            categ:[],
            paused:false,
        }
    },
	beforeRouteUpdate (to, from, next) {
    	this.resultposts = to.params.posts
    	this.resultfor = to.params.id
		this.fetchData();
    next()
	},
	methods:{
        // async getResults(page = 1) {
		// 	await Mainaxios.get('api/web/'+this.CategID+'?page=' + page)
		// 		.then(response => {
		// 			this.news = response.data;
		// 		});
		//     },
        async fetchData(){
			await Mainaxios.get("api/search?q="+this.resultfor)
                .then(({data}) => (this.resultposts = data.data))
                .catch( error => {
                    let status = error.response.status;
                    this.searchItem = ''; 
                    if(status==400){
                        this.toggleSearch();
                        Swal.fire({
                            title: 'No Results!',
                            text: "Try Somthing Else! No such post in our record.",
                            icon: 'warning'
                            })
                    }else{
                        Swal.fire('error!', 'There is somthing wrong. Please Try Again!','warning');
                     
                    }
                });
        },
        
    },
    created(){
		this.fetchData();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>