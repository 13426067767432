<template>
    <div class="container">
        <div class="col-md-12 row">
            <div class="col-md-3" v-for="video in videos" :key="video.snippet.resourceId.videoId">
                <article class="article" >
                    <div class="article-img">
                        <router-link :to="{ name: 'singleVideo', params:{ id: video.snippet.resourceId.videoId, video: video }}">
                            <img height="200" :src="video.snippet.thumbnails.medium.url" alt="">
                        </router-link>
                        <ul class="article-info">
                            <li class="article-type"><fa :icon="['fas', 'camera']" /></li>
                        </ul>
                    </div>
                    <div class="article-body">
                        <h3 class="article-title">
                            <router-link :to="{ name: 'singleVideo', params:{ id: video.snippet.resourceId.videoId, video: video }}">
                            {{video.snippet.title|truncate(40)}}</router-link></h3>
                        <ul class="article-meta">
                            <li><fa :icon="['far', 'clock']" /> {{video.snippet.publishedAt|TimeAgo}}</li>
                        </ul>
                        <p class="text-justify" v-snip:js="4"  v-html="video.snippet.description"></p>
                    </div>
                </article>
            </div>
        </div>

        <!-- <video-group :videos="videos"></video-group> -->
        <div class="col-md-12">
            <nav v-show="this.totalVideo>this.perPage">
                <ul class="pagination">
                    <li class="page-item" :class="{'disabled': (this.nxtToken==undefined) ? false : (this.prevToken!=undefined) ? false : true , 'active': prevPage }">
                    <a class="page-link" @click.prevent="getPrevToken"  href="#">Previous</a>
                    </li>
                    <li class="page-item" :class="{'disabled': (this.prevToken==undefined) ? false : (this.nxtToken!=undefined) ? false : true , 'active': nxtPage }">
                    <a class="page-link" @click.prevent="getNextToken"  href="#">Next</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
// import VideoGroup from './VideoGroup'
import Search from './Search';

    export default {
        components:{
            // VideoGroup,
        },
        data(){
            return{
                videos:null,
                videosData:null,
                pgToken:'',
                nxtPage:false,
                prevPage:false,
                nxtToken:null,
                prevToken:null,
                perPage:null,
                totalVideo:null,
                nextdisabled: false,
                prevdisabled: false,
                pcount:0,
                ncount:1,
            }
        },
        watch:{
            pgToken(){
                Search({
                apiKey: 'AIzaSyDLH5KakU-AjflfZrKW8TvyphhTHq1Qp0s',
                playlist: 'PL1Jd85LB_muQD68-Onsji-F5Yw7wbMwrn',
                pageToken: this.pgToken
            }, response => this.videosResults(response));

            }
        },
        async created(){
           await Search({
                apiKey: 'AIzaSyDLH5KakU-AjflfZrKW8TvyphhTHq1Qp0s',
                playlist: 'PL1Jd85LB_muQD68-Onsji-F5Yw7wbMwrn',
                pageToken: this.pgToken
            }, response => this.videosResults(response));
        },

        methods:{
            videosResults(result){
                this.videos = result.items
                this.perPage = result.pageInfo.resultsPerPage,
                this.totalVideo = result.pageInfo.totalResults,
                this.nxtToken = result.nextPageToken
                this.prevToken = result.prevPageToken
            },
            getNextToken(){
                if(this.nxtToken){
                    this.pgToken = this.nxtToken
                    this.nxtPage = true;
                    this.prevPage = false;
                }else{
                     this.nextdisabled = true;
                }

            },
            getPrevToken(){
                if(this.prevToken){
                    this.pgToken = this.prevToken
                    this.prevPage = true;
                    this.nxtPage = false;
                }else{
                     this.prevdisabled = true;
                }

            },
        }
    }
</script>
