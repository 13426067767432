<template>
    <div>
        
    </div>
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "SideComponent",
  props: {
    categID: Number,
  },
  data(){
        return{
            relatedposts:[],
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/relatedposts/"+this.categID).then(({data}) => (this.relatedposts = data));
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>