<template>
  <div>
    <carousel :autoplay="true" :loop="true" :paginationEnabled="false">
      <slide v-for="(banner,index) in banners" :key="index">
        <article class="article thumb-article" >
          <div class="article-img" v-for="(image,index) in banner.images" :key="index" >
            <img v-if="index <= 0" class="banner-img" :src="$urlpath+image" alt="">
          </div>
          <div class="article-body">
            <ul class="article-info">
              <li class="article-category"><router-link :to="'/category/'+banner.categ_id" >{{banner.categ_name}}</router-link></li>
              <li class="article-type"><fa :icon="['fas', 'camera']" /></li>
            </ul>
            <h2 class="article-title"><router-link :to="'/single-post/'+banner.id">{{banner.title|truncate(40)}}</router-link></h2>
            <ul class="article-meta">
              <li><fa :icon="['fas', 'clock']" /> {{banner.postdate|calendar}}</li>
            </ul>
          </div>
        </article>
      </slide>
      
    </carousel>

    
    <marquee-text :duration="50" :repeat="10" @mouseover="stopMarquee" :paused='paused' @mouseleave="startMarquee">
    <div class="latest-news-marquee-area">
        <div class="simple-marquee-container">
            <div class="marquee">
                <ul class="marquee-content-items">
                    <li v-for="marquee in marquees" :key="marquee.id">
                        <router-link :to="'/single-post/'+marquee.id"><span class="latest-news-time">{{marquee.postdate|Time}}</span>
                {{marquee.title|truncate(100)}}</router-link>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
    </marquee-text>
    
    
  </div>
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "Banner",
  props: {
    msg: String,
  },
  data(){
        return{
            banners:[],
            marquees:[],
            paused:false,
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/banner").then(({data}) => (this.banners = data));
            await Mainaxios.get("api/marquee").then(({data}) => (this.marquees = data));
        },
        stopMarquee(){
            this.paused = true;
        },
        startMarquee(){
            this.paused = false;
        }
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.simple-marquee-container {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    z-index: 1;
    height: 65px;
}

.marquee-content-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    position: relative;
    z-index: 1;
    height: 65px;
}

.marquee-content-items li {
    background-color: #101010;
    padding: 0 20px;
    height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.marquee-content-items li:nth-child(even) {
    background-color: #1c1c1c;
}

.marquee-content-items li a {
    font-size: 18px;
    color: #fff;
    display: inline-block;
}

.marquee-content-items li a > span {
    background-color: #ff0303;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 300;
    margin-right: 20px;
}

</style>