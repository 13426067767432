<template>
  <div>
    <!-- SECTION -->
		<div class="section">
			<!-- CONTAINER -->
			<div class="container">
				<!-- ROW -->
				<div class="row">
					<!-- Main Column -->
					<div class="col-md-12">
						<!-- section title -->
						<div class="section-title">
							<h2 class="title">Trending Posts</h2>
							<!-- tab nav -->
							<ul class="tab-nav pull-right">
								<li class="active"><a @click="changeTab(0)" data-toggle="tab" href="#tab1">All</a></li>
								<li v-for="(obj,index) in categories" :key="index">
                                    <a data-toggle="tab" @click="changeTab(obj.id)" href="#tab">{{obj.categ_name}}</a>
                                </li>
							</ul>
							<!-- /tab nav -->
						</div>
						<!-- /section title -->
						
						<!-- Tab content -->
						<div class="tab-content">
							<!-- tab1 -->
							<div id="tab" class="tab-pane fade in active">
								<!-- row -->
								<div class="row">
									<!-- Column 1 -->
									<div class="col-md-3 col-sm-6" v-for="obj in banners.slice(0,4)" :key="obj.key">
										<!-- ARTICLE -->
										<article class="article">
												<div class="article-img">
											<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
												<slide v-for="(image,index) in obj.images" :key="index">
													<router-link :to="'/single-post/'+obj.id">
														<img height="200" :src="$urlpath+image" alt="">
													</router-link>
													<ul class="article-info">
														<li class="article-type"><fa :icon="['fas', 'camera']" /></li>
													</ul>
												</slide>
											</carousel>
												</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</li>
												</ul>
											</div>
										</article>
										<!-- /ARTICLE -->
									</div>
									<!-- /Column 1 -->
									
								</div>
								<!-- /row -->
								
								<!-- row -->
								<div class="row">
									<!-- Column 1 -->
									<div class="col-md-4 col-sm-6">
										<!-- ARTICLE -->
										<article class="article widget-article" v-for="obj in banners.slice(4,6)" :key="obj.key" >
											<div class="article-img">
												<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
													<slide v-for="(image,index) in obj.images" :key="index">
														<router-link :to="'/single-post/'+obj.id">
															<img height="80" :src="$urlpath+image" alt="">
														</router-link>
													</slide>
												</carousel>
											</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|myDate}}</li>
												</ul>
											</div>
										</article>
										<!-- /ARTICLE -->
									</div>
									<!-- /Column 1 -->
									
									<!-- Column 2 -->
									<div class="col-md-4 col-sm-6">
										<!-- ARTICLE -->
										<article class="article widget-article" v-for="obj in banners.slice(6,8)" :key="obj.key" >
											<div class="article-img">
												<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
													<slide v-for="(image,index) in obj.images" :key="index">
														<router-link :to="'/single-post/'+obj.id">
															<img height="80" :src="$urlpath+image" alt="">
														</router-link>
													</slide>
												</carousel>
											</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|myDate}}</li>
												</ul>
											</div>
										</article>
										<!-- /ARTICLE -->
									
									</div>
									<!-- /Column 2 -->
									
									<!-- /Column 3 -->
									<div class="col-md-4 hidden-sm">
										<!-- ARTICLE -->
										<article class="article widget-article" v-for="obj in banners.slice(8,10)" :key="obj.key" >
											<div class="article-img">
												<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
													<slide v-for="(image,index) in obj.images" :key="index">
														<router-link :to="'/single-post/'+obj.id">
															<img height="80" :src="$urlpath+image" alt="">
														</router-link>
													</slide>
												</carousel>
											</div>
											<div class="article-body">
												<h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
												<ul class="article-meta">
													<li><fa :icon="['far', 'clock']" /> {{obj.postdate|myDate}}</li>
												</ul>
											</div>
										</article>
										<!-- /ARTICLE -->
									
									</div>
									<!-- /Column 3 -->
								</div>
								<!-- /row -->
							</div>
							<!-- /tab1 -->

						</div>
						<!-- /tab content -->
					</div>
					<!-- /Main Column -->
				</div>
				<!-- /ROW -->
			</div>
			<!-- /CONTAINER -->
		</div>
		<!-- /SECTION -->
    
    
  </div>
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "Banner",
  props: {
    msg: String,
  },
  data(){
        return{
            banners:[],
            categories:[],
            paused:false,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        getResults(page = 1) {
        Mainaxios.get('api/banner?page=' + page)
            .then(response => {
                this.posts = response.data;
            });
        },
        async fetchData(){
            await Mainaxios.get("api/categNews/0").then(({data}) => (this.banners = data));
            await Mainaxios.get("api/fetch_categ").then(({data}) => (this.categories = data));
        },
        stopMarquee(){
            this.paused = true;
        },
        startMarquee(){
            this.paused = false;
        },
		async changeTab(id){
			await Mainaxios.get("api/categNews/"+id).then(({data}) => (this.banners = data));
		}
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>