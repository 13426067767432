import Mainaxios from 'axios';
// let youtubeAxios = require('axios');

// let localtoken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMDFkZTJiOGQ5ZTY5OTdjYzJmOTQyMmIzMjczNzUxYjRlMzhjMzNmNzg1MzkzNzgxMmYwMzRiMTAwOTUxOTM3NTE2M2U4Nzc1MzlhZGJjYjEiLCJpYXQiOjE2MjIzNzQwNDcuOTE5NDI3LCJuYmYiOjE2MjIzNzQwNDcuOTE5NDMyLCJleHAiOjE2NTM5MTAwNDcuOTEyMzg2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.BYdCBFyWzaYfz1sEAuR6yFqnng-3JCBw-BFakbfUX76ZOaZfR9hpGA--fcWsQnbHRfsj6i6f68UQGIahTF9iqQcdNrAUmrsUEyELPtFRETh5qchJ0ZutkEvcUjmTHOgWOLtrZGqh31JKr7xriYywmjTOCPMi-h01xSMGCN6MNmOBsPTUF2rI2yYwtrX8uKjskIvGQfP3eRejzMP7jTd7xXvVDF1izuzX7Xsjld9w0tL-9Zd_1NOrVaiVWT6fyfuaBdt9BZQ89MmH0WfzSff0J8FQSZwAkpm0nsmeRiNE6L8lEevP1mbc17AK0rqPRFZTXNB-Mv7xclhJvGSaZshABmdCmLcBToYqykBQfUph6li9Vbf-JW5MSdRI9ydUPrM34rytOC83Cpv8Ud4lHd5_bB9pCK25sFIP-jVq_gTJXvijruyr15wfcNx27EzfqxOklzYr6PxGs5nf0t1ImsNOR3b_bqvkS8YlT6MYf7xSaVxKf5nn0n3fSbQTRkRmdCMtbjgRy-Zoi9fV4N0EtPFcvzFBEyzX5WTFtGzbuesi09su-HobsM7HDVVNTRO-fZS9q0D5AtwEduRy19TftUdcklSLsUwEvI_h-bSjGV4YIFBD3OlineqB8ZxRliMGOMFaedu30ghjJFrHN9SBzmpEqlsgVfyASKyAsn5t_xTmCR0";
let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDgwN2Y1ZDA1OTY1OTgxYmI2NjQxNDYyYWYzOTc0OTU1MjQxNjNiNzk3YWZlODFiYjkwOTE3OWI1NTY3ZGFiODhmNWI5Y2Y1YWFlNmYzOWYiLCJpYXQiOjE2MjI2NDI3MjcuMTY1OTI5LCJuYmYiOjE2MjI2NDI3MjcuMTY1OTM0LCJleHAiOjE2NTQxNzg3MjcuMTYxNDI4LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.Cy05FjdNkTFSxrqu4xteaAa6dvQS4FvUIkqJ9R1zaElPFRw47lVbf4GpnynZXjz5nXPxkbK12_kJ3NmeB1HtSUkN_BSuSvF9OuJGa61KP94bkek57OoBI7sLNMfX0bD4SKLkusJGPYzGUqpAim7i6oT4e3da7qzi0adds6W_ZRLGuidpXldceMpNgsGiggjCplCZyRYKzEkIaOLPEcZCAB5SzoURmPxfiDFyPTX_W9tc-iClfi_rhbKOayoOr6DaWgo5tGJBvUZS5up3u8Dut1zQZi7OOQMcJb--C7uFiync2QlnlnEVJErgdjfTQUFPn_VoLPmn4fzKqBRrxKQN0c0ia1sa7nk7hK2eSyEFdbLgv8YeT7lskXGEhx0dxGXvCi5hYVuqRnO6D4RsmFEfzoEcJlADasFqnij0m_3GWTrOYM_mZ2D66fSkGeltifarbK8OEhrrWHZTZYwPPWfJLj6mjZGGwlkTXWKvUCY5kxAmAQsDQFn8c96DjevuRQsbVZeXqlFUIN01i8u0N8udvsZxAkum-kjvyBLhMhPTBtjK2gyvWHgZ29kwIQqBQ6HbDnpCdjvlGVETlYKIUHq9A6xceO7rNolvV8s-wFRDlXiz-GIM_Vn2wsyKrFqGLc49oxWpy6_7E7UgZkhNb7HBi6nskCWmihCF9LT87Xqur24";

export default  Mainaxios.create({
    baseURL: 'https://admin.bharatsamachartvnews.com/',
    headers: {
        "Authorization": `Bearer ${token}`
    }
});

