<template>
  
    <div class="section">
			<div class="container">
				<div class="row">
					<div class="col-md-8">
						<div class="section-title">
							<h2 class="title">{{title}}</h2>
						</div>
						
						<article class="article row-article" v-for="obj in news.data" :key="obj.id">
							<div class="article-img"  v-if="obj.images.length>=1">
                                <carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
                                    <slide v-for="(image,index) in obj.images" :key="index">
                                        <router-link :to="'/single-post/'+obj.id">
                                            <img height="280" :src="$urlpath+image" alt="">
                                        </router-link>
                                    </slide>
                                </carousel>
							</div>
							<div class="article-img" v-else>
								<router-link :to="'/single-post/'+obj.id">
									<img height="280" :src="$profilepath+'logo.png'" alt="">
								</router-link>
							</div>
							<div class="article-body">
								<ul class="article-info">
									<li class="article-category"><router-link :to="'/category/'+obj.categ_id">{{obj.categ_name}}</router-link></li>
									<li class="article-type"><fa :icon="['far', 'file']" /></li>
								</ul>
								<h3 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h3>
                                <ul class="article-meta">
                                    <li><fa :icon="['far', 'clock']" /> {{obj.postdate|myDate}}</li>
                                </ul>
                                <p class="text-justify" v-snip:js="6"  v-html="obj.content"></p>
							</div>
						</article>
						
						
						<div class="article-pagination">
							<pagination :limit="1" :data="news" @pagination-change-page="getResults">
                                
                            </pagination>
						</div>
                        
					</div>
					
					<Bottom-Side />
					
				</div>
			</div>
		</div>
    
 
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "SideComponent",
  props: {
    title: String,
  },
  data(){
        return{
            news:{},
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async getResults(page = 1) {
			await Mainaxios.get('api/popularNews?page=' + page)
				.then(response => {
					this.news = response.data;
				});
		    },
        async fetchData(){
            await Mainaxios.get("api/popularNews").then(({data}) => (this.news = data));
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

@media only screen and (min-width: 980px) {
  .page-link {
    all: none;
  }
}
</style>