<template>
  <div class="home">
    
    <Banner/>
    <Trending-News/>
		<div class="section">
			<div class="container">
				<div class="row">
					<div class="col-md-8">
						<div class="row">
							<span v-for="obj in categories.slice(0,2)" :key="obj.id">
								<Categ-News :categID="obj.id" :categName="obj.categ_name" />
							</span>
						</div>
						
						<span v-for="obj in categories.slice(2,3)" :key="obj.id">
							<CategNews :categID="obj.id" :categName="obj.categ_name" />
						</span>
						
					</div>
					
					<Side-Component/>
					
				</div>
			</div>
		</div>
		
		<bottom-ads />

		
		<Video-Component title="Popular Video"/>
		
		<Popular-News title="Popular Posts"/>
		
		
		

    
  </div>
</template>

<script>
import Mainaxios from '../Api'
import BottomAds from '../components/BottomAds.vue';

export default {
  name: "Home",
  components: {
    BottomAds
  },
  data(){
        return{
            banners:[],
            categories:[],
            paused:false,
            
        }
    },
	 mounted(){
        this.fetchData();
    },
  methods:{
	async fetchData(){
		await Mainaxios.get("api/categories").then(({data}) => (this.categories = data));
	},
}
};
</script>
