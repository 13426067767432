<template>
  
    <div class="col-md-6 col-sm-6">
        <!-- section title -->
        <div class="section-title">
            <h2 class="title">{{categName}}</h2>
        </div>
        <!-- /section title -->
        
        <!-- ARTICLE -->
        <article class="article" v-for="obj in news.slice(0,1)" :key="obj.key">
            <div class="article-img">
                <carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
                    <slide v-for="(image,index) in obj.images" :key="index">
                        <router-link :to="'/single-post/'+obj.id">
                            <img height="200" :src="$urlpath+image" alt="">
                        </router-link>
                        <ul class="article-info">
                            <li class="article-type"><fa :icon="['fas', 'camera']" /></li>
                        </ul>
                    </slide>
                </carousel>
            </div>
            <div class="article-body">
                <h3 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h3>
                <ul class="article-meta">
                    <li><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</li>
                </ul>
                <p class="text-justify" v-snip:js="4"  v-html="obj.content"></p>
            </div>
        </article>
        <!-- /ARTICLE -->
        
        <!-- ARTICLE -->
        <article class="article widget-article" v-for="obj in news.slice(1,3)" :key="obj.key">
            <div class="article-img">
               <carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
                    <slide v-for="(image,index) in obj.images" :key="index">
                        <router-link :to="'/single-post/'+obj.id">
                            <img height="80" :src="$urlpath+image" alt="">
                        </router-link>
                    </slide>
                </carousel>
            </div>
            <div class="article-body">
                <h4 class="article-title"><router-link :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link></h4>
                <ul class="article-meta">
                    <li><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</li>
                </ul>
            </div>
        </article>
        <!-- /ARTICLE -->
    </div>
    
 
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "SideComponent",
  props: {
    categID: Number,
    categName: String,
  },
  data(){
        return{
            news:[],
            marquees:[],
            paused:false,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
			await Mainaxios.get("api/categNews/"+this.categID).then(({data}) => (this.news = data));
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>